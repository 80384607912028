import { AppConfig } from "app/core";
import { commonEnvironment } from "./environment-common";

export const environment: AppConfig = {
  ...commonEnvironment,
  botName: "douane",
  backendBaseUrl: "https://ui-api.douanebot-out-prod.probayes.net/v0/",
  headerLogoUrl: "/assets/specific/douane/avatar.svg",
  reducedBotLogoUrl: "/assets/specific/douane/avatar.svg",
  reducedBotHasWelcomeText: true,
  messageAvatarUrl: "/assets/specific/douane/avatar.svg",
};
