import { environment } from "../../../environments/environment";
import { EnvironmentInjector, InjectionToken, inject, runInInjectionContext } from "@angular/core";
import { AppConfig } from "./config.model";
import { BackendConfigApiClient } from "./connector/backend-config.apiclient";
import { timer } from "rxjs";

/** @return An object containing all the current URL query parameters */
// TODO move to utils
function retrieveQueryParams() {
  // TODO restrict to subset of params
  const params = new URL(window.location.href).searchParams;
  const result = {} as any;
  params.forEach((value: string, key: string) => (result[key] = value));
  return result;
}

/**
 * Launches an asynchronous task that will retrieve the backend config and update the passed config
 * with the data retrieved from the server
 */
function asyncRetrieveBackendConfig(config: AppConfig) {
  const environmentInjector = inject(EnvironmentInjector);
  timer(0).subscribe(() =>
    runInInjectionContext(environmentInjector, () => {
      const apiClient = inject(BackendConfigApiClient);
      apiClient.retrieveConfig(config.backendBaseUrl + "ui-config/").subscribe({
        next: (backendConfig) => {
          if (config.headerBanner) {
            config.headerBanner.text.set(backendConfig.banner_txt);
          }
        },
        error: (err) => console.error(`Unable to retrieve backend config, ${err}`),
      });
    })
  );
}

function convertToExpectedType(config: any) {
  /*Convert boolean*/
  if (config) {
    for (const [key, value] of Object.entries(config)) {
      if (value === "True" || value === "true") {
        config[key] = true;
      } else if (value === "False" || value === "false") {
        config[key] = false;
      }
    }
  }
  /*Convert numbers*/
  if (config?.delayInConversationMessagesDisplayInMs) {
    config["delayInConversationMessagesDisplayInMs"] = Number(
      config.delayInConversationMessagesDisplayInMs
    );
  }
  return config;
}

// TODO test
export const APP_CONFIG = new InjectionToken<AppConfig>("app.config", {
  factory: () => {
    const config = Object.assign(
      { ...environment },
      convertToExpectedType((window as any)["BOT_WEB_INTERFACE_SETTINGS"]),
      // TODO JSU security issue: no check is done on allowed config params
      // attacker could forge a malicious URL that a user could use
      retrieveQueryParams()
    );

    if (config.hasConfigurationFromBackend) {
      asyncRetrieveBackendConfig(config);
    }
    return config;
  },
});

export { AppConfig, HeaderActionType } from "./config.model";
